<script>
import { reactive, computed, watch } from '@vue/composition-api';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { IntegrationsApi } from '@/api/index';

export default {
  name: 'IntegrationsView',

  setup(props, { root }) {
    const queryClient = useQueryClient();
    const store = root.$store;

    // Log environment variables
    console.log('Environment Variables:', {
      FB_CLIENT_ID: process.env.VUE_APP_FB_CLIENT_ID,
      FB_CONFIG_ID: process.env.VUE_APP_FB_CONFIG_ID
    });

    const state = reactive({
      confirmDisconnect: {
        show: false,
        platform: '',
        action: null
      },
      isFBLoaded: false,
      isConnecting: false,
      isDisconnecting: false
    });

    // Query for fetching integration status
    const { data: integrationStatus, isLoading: isLoadingStatus } = useQuery({
      queryKey: ['facebook-status'],
      queryFn: async () => {
        const response = await IntegrationsApi.getFacebookStatus();
        return response.data;
      },
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    // Update computed properties to match the actual data structure
    const isConnected = computed(() => integrationStatus.value?.connected || false);

    const integrationData = computed(() => integrationStatus.value?.integration || null);

    // Connect mutation should also follow the same structure
    const connectMutation = useMutation({
      mutationFn: code => IntegrationsApi.connectFacebook(code),
      onSuccess: response => {
        queryClient.setQueryData(['facebook-status'], {
          connected: true,
          integration: response.data
        });
        store.dispatch('notification/addSuccessNotification', 'Successfully connected to Facebook Business');
      },
      onError: error => {
        store.dispatch('notification/addFailureNotification', error.message || 'Failed to connect Facebook Business account');
      }
    });

    // Disconnect mutation should match too
    const disconnectMutation = useMutation({
      mutationFn: () => IntegrationsApi.disconnectFacebook(),
      onSuccess: () => {
        queryClient.setQueryData(['facebook-status'], {
          connected: false,
          reason: 'DISCONNECTED'
        });
        store.dispatch('notification/addSuccessNotification', 'Successfully disconnected Facebook Business account');
      }
    });

    const loadFacebookSDK = () => {
      return new Promise(resolve => {
        if (!document.getElementById('facebook-jssdk')) {
          window.fbAsyncInit = () => {
            window.FB.init({
              appId: process.env.VUE_APP_FB_CLIENT_ID,
              // appId: '1017366750431104',
              // appId: '1683156579299975',
              cookie: true,
              xfbml: true,
              version: 'v21.0'
            });
            state.isFBLoaded = true;
            resolve();
          };
          const script = document.createElement('script');
          script.id = 'facebook-jssdk';
          script.src = 'https://connect.facebook.net/en_US/sdk.js';
          script.async = true;
          script.defer = true;
          document.head.appendChild(script);
        } else if (window.FB) {
          state.isFBLoaded = true;
          resolve();
        }
      });
    };

    const handleFacebookLogin = async () => {
      if (!state.isFBLoaded) {
        await loadFacebookSDK();
      }
      window.FB.login(
        async response => {
          if (response.authResponse?.code) {
            try {
              await connectMutation.mutateAsync(response.authResponse.code);
            } catch (error) {
              console.error('Failed to connect Facebook:', error);
              store.dispatch('notification/addFailureNotification', 'Failed to connect Facebook Business account');
            } finally {
              state.isConnecting = false;
            }
          } else {
            state.isConnecting = false;
            store.dispatch('notification/addFailureNotification', 'Facebook authorization failed');
          }
        },
        {
          config_id: process.env.VUE_APP_FB_CONFIG_ID,
          response_type: 'code',
          override_default_response_type: true,
          auth_type: 'rerequest',
          scope: 'instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement,pages_manage_posts,business_management'
        }
      );
    };

    // Main handlers
    const handleFacebookConnection = async () => {
      if (isConnected.value) {
        state.confirmDisconnect = {
          show: true,
          platform: 'Facebook',
          action: async () => {
            try {
              state.isDisconnecting = true;
              await disconnectMutation.mutateAsync();
            } catch (error) {
              console.error('Failed to disconnect Facebook:', error);
              store.dispatch('notification/addFailureNotification', 'Failed to disconnect Facebook Business account');
            } finally {
              state.isDisconnecting = false;
            }
          }
        };
      } else {
        try {
          state.isConnecting = true;
          if (!state.isFBLoaded) {
            await loadFacebookSDK();
          }
          if (!window.FB) {
            throw new Error('Facebook SDK not loaded');
          }

          await handleFacebookLogin();
        } catch (error) {
          console.error('Error initializing Facebook auth:', error);
          store.dispatch('notification/addFailureNotification', 'Failed to initialize Facebook Business login');
          state.isConnecting = false;
        }
      }
    };

    const handleDisconnectConfirm = async () => {
      if (state.confirmDisconnect.action) {
        await state.confirmDisconnect.action();
      }
      state.confirmDisconnect = {
        show: false,
        platform: '',
        action: null
      };
    };

    // Debug logs
    watch(integrationStatus, newStatus => {
      console.log('Integration status updated:', newStatus);
    });

    // Return composable values and functions
    return {
      state,
      isConnected,
      integrationData,
      isLoadingStatus,
      handleFacebookConnection,
      handleDisconnectConfirm
    };
  }
};
</script>

<template>
  <div class="integrations-page">
    <div class="integrations-container q-pa-md">
      <div class="q-mb-md">
        <div class="text-h4 text-weight-bold q-mb-md capitalize">Integrations</div>
        <div class="text-body1 text-grey-8 max-width-600">Use Flashy's integrations to easily streamline your production and marketing workflow.</div>
      </div>

      <!-- Publishing Integrations -->
      <q-expansion-item class="custom-expansion q-mb-md" icon="movie" label="Publishing" caption="Share your videos across platforms" default-opened header-class="text-grey-6">
        <q-card>
          <q-card-section>
            <div class="text-body2 text-grey-7 q-mb-lg">Efficiently and safely share your finished videos without the hassle of downloading and uploading large files.</div>

            <div class="integration-list">
              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-youtube" size="32px" class="q-mr-md text-red-8" />
                  <div class="text-subtitle1">YouTube</div>
                </div>
                <q-btn outline no-caps label="Coming soon!" color="grey-7" disable class="q-px-md" />
              </div>

              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-vimeo" size="32px" class="q-mr-md text-blue-8" />
                  <div class="text-subtitle1">Vimeo</div>
                </div>
                <q-btn outline no-caps label="Coming soon!" color="grey-7" disable class="q-px-md" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <!-- Marketing Integrations -->
      <q-expansion-item class="custom-expansion q-mb-md" icon="campaign" label="Marketing" caption="Connect with advertising platforms" default-opened header-class="text-grey-6">
        <q-card>
          <q-card-section>
            <div class="text-body2 text-grey-7 q-mb-lg">Directly transmit your video advertisements to your advertising account with comprehensive analytics.</div>

            <div class="integration-list">
              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-google" size="32px" class="q-mr-md text-red-8" />
                  <div class="text-subtitle1">Google Ads</div>
                </div>
                <q-btn outline no-caps label="Coming soon!" color="grey-7" disable class="q-px-md" />
              </div>

              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-facebook" size="32px" class="q-mr-md text-blue-8" />
                  <div class="text-subtitle1">Meta Ad Manager</div>
                </div>
                <q-btn
                  :label="isConnected ? 'Disconnect' : 'Connect'"
                  :color="isConnected ? 'green-8' : 'blue-grey-8'"
                  outline
                  no-caps
                  @click="handleFacebookConnection"
                  class="q-px-md"
                  :loading="state.isConnecting || state.isDisconnecting"
                />
              </div>

              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-linkedin" size="32px" class="q-mr-md text-blue-8" />
                  <div class="text-subtitle1">LinkedIn Ad Manager</div>
                </div>
                <q-btn outline no-caps label="Coming soon!" color="grey-7" disable class="q-px-md" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>

      <!-- Cloud Storage Integrations -->
      <q-expansion-item class="custom-expansion q-mb-md" icon="cloud" label="Cloud storage" caption="Connect your cloud storage services" default-opened header-class="text-grey-6">
        <q-card>
          <q-card-section>
            <div class="text-body2 text-grey-7 q-mb-lg">Seamlessly integrate with popular cloud storage providers for efficient file management.</div>

            <div class="integration-list">
              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-google-drive" size="32px" class="q-mr-md text-red-8" />
                  <div class="text-subtitle1">Google Drive</div>
                </div>
                <q-btn outline no-caps label="Coming soon!" color="grey-7" disable class="q-px-md" />
              </div>

              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-dropbox" size="32px" class="q-mr-md text-blue-8" />
                  <div class="text-subtitle1">Dropbox</div>
                </div>
                <q-btn outline no-caps label="Coming soon!" color="grey-7" disable class="q-px-md" />
              </div>

              <div class="integration-item q-pa-md">
                <div class="row items-center">
                  <q-icon name="mdi-onedrive" size="32px" class="q-mr-md text-blue-8" />
                  <div class="text-subtitle1">OneDrive</div>
                </div>
                <q-btn outline no-caps label="Coming soon!" color="grey-7" disable class="q-px-md" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </div>

    <!-- Disconnect Confirmation Dialog -->
    <q-dialog v-model="state.confirmDisconnect.show">
      <q-card>
        <q-card-section>
          <div class="text-h6">Disconnect {{ state.confirmDisconnect.platform }}?</div>
        </q-card-section>
        <q-card-section> Are you sure you want to disconnect your {{ state.confirmDisconnect.platform }} account? </q-card-section>
        <q-card-actions align="right">
          <q-btn flat no-caps label="Cancel" color="grey-7" v-close-popup />
          <q-btn no-caps label="Disconnect" color="red-8" @click="handleDisconnectConfirm" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>
.integrations-container {
  max-width: 800px;
  height: calc(100vh - 76px);
  margin: 0 auto;
  text-align: left;
  overflow-y: scroll;
}

.max-width-600 {
  max-width: 600px;
}

.integration-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s ease;
}

.integration-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.integration-item:last-child {
  border-bottom: none;
}

.custom-expansion {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.custom-expansion:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.q-expansion-item__content {
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.integration-item .q-btn {
  width: 175px;
}
</style>
